:root {
  --navbarDark: #1B1B1B;
  --mainDark: #1A1A1A;
  --subDark: #202020;
  --stroke: #414141;
  --footerDark: #606060;
  --btnDark: #272727;
  --text: #F1F1F1;
  --greenText: #BCFF66;
  --accent: #666CFF;
  --firstPrize: #665B32;
  --secondPrize: #656565;
  --thirdPrize: #583F22;
}

body > #root > div {
  height: 100vh;
}

/* --- TOP NAVBAR --- */

.logo {
  height: 50px;
  vertical-align: middle;
}

.navbarBg {
  background-color: var(--navbarDark);
}

.fullscreenBtn {
  float: right !important;
  margin-top: 8px;
  margin-right: 10px;
  margin-bottom: auto;
  background-color: var(--btnDark);
  width: 50px !important;
  height: 50px !important;
}

.settingsBtn {
  float: right !important;
  margin-top: 8px;
  margin-bottom: auto;
  background-color: var(--btnDark);
  width: 50px !important;
  height: 50px !important;
}

.gameTitle {
  text-align: center;
  padding: 0px 0px 10px 0px;
  margin: -10px 0px 10px 0px;
  font-size: 20px;
}

.gameSubtitle {
  text-align: center;
  margin-top: -60px;
  margin-left: 90px;
  font-size: 16px;
}

/* --- BACKGROUND --- */

.mainBg {
  background-color: var(--mainDark);
}

/* --- MAIN COUNTDOWN TIMER --- */

.timerBox {
  padding: 0 0 10px 100px;
}

.ant-progress-text {
  margin-top: 60px !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 150px !important;
}

/* --- PRIZE BOXES --- */

.prizesColumn {
  margin-top: 20px;
}

.timeLapsedLabel {
  color: white;
  font-weight: bold;
  padding-left: 50px;
}

.totalTimeLapsed {
  font-size: 20px;
  background-color: var(--subDark);
  color: lightgray;
  border: 2px solid var(--stroke);
  width: 75px;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  margin: 10px 0 20px 115px;
}

.firstPrizeCard {
  border: 3px solid var(--firstPrize) !important;
}

.secondPrizeCard {
  border: 3px solid var(--secondPrize) !important;
}

.thirdPrizeCard {
  border: 3px solid var(--thirdPrize) !important;
}

.prizeLabel {
  margin-right: 30px;
  font-size: 20px;
}

.prizeText {
  font-size: 50px;
  margin: 0 40px 0 0 !important;
}

.prizesCard > .ant-card-body {
  padding: 10px 0 10px 0 !important;
}

.prizesCard {
  background-color: var(--subDark);
  border-radius: 5px;
  margin-bottom: 20px;
  width: 300px;
  text-align: center;
  color: var(--text);
}

.footer {
  background-color: var(--footerDark);
  margin-top: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media (max-width: 1250px) {
  .prizesColumn {
    padding-left: 70px;
  }
}

@media (max-width: 1080px) {
  .prizesColumn {
    visibility: hidden;
  }
}

@media (max-width: 850px) {
  .activeBlindLeveltext {
    font-size: 30px !important;
  }

  .activeBlindGreenText {
    font-size: 30px !important;
    padding-top: 30px;
  }

  .buyinText {
    font-size: 15px !important;
  }
}

/* --- CURRENT BLIND CARD --- */

.flexBoxCol {
  display: flex;
  align-items: center;
}

.blindsCard {
  background-color: var(--subDark);
  border: 3px solid var(--stroke);
  border-radius: 5px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.blindsCard > .ant-card-body {
  padding: 0 !important;
}

.blindsList {
  margin-top: 10px;
}

.ant-col-2:nth-child(1) > h4 {
  text-align:right;
  margin: 0;
  color: grey;
}

.blind-item-selected {
  color: white !important;
}

.blind-item {
  text-align:right;
  margin: 0;
  color: grey;
}

.verticalLine {
  margin-top: 10px;
  margin-left: 15px;
  border-left: 2px solid gray;
  height: 90px;
}

.activeBlindLeveltext {
  width: 90%;
  font-size: 50px;
  margin: 0;
  padding-left: 40px;
}

.activeBlindGreenText {
  margin: 0;
  margin-top: -5px;
  padding-bottom: 5px;
  padding-right: 40px;
  font-size: 70px;
  color: var(--greenText);
}

.buyinText {
  padding-left: 10px;
  padding-right: 10px;
}

/*SETTINGS PAGE*/
.editable-cell-value-wrap {
  border: 2px solid var(--stroke);
  border-radius: 5px;
  padding: 5px;
}

.changeAllBtn {
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
}

.addNewLevelBtn {
  margin-left: 15px;
  margin-top: 10px !important;
  width: 500px;
}

.tableHeaderLabels {
  display: flex;
}

.tableHeaderItem {
  margin-left: 70px;
  flex: 1;
  font-size: 12px;
  font-weight: bold;
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
}

.ant-tabs-content-holder {
  background-color: var(--mainDark);
  border-radius: 5px;
  height: 100%;
  padding: 10px;
}

.ant-table {
background-color: var(--mainDark) !important;
}

.ant-tabs-nav-list {
  min-width: 552px;
}

.ant-tabs-tab {
  width: 184px;
}

.ant-tabs-tab-active {
  background-color: var(--accent) !important;
}

.ant-tabs-tab-btn {
  color: lightgray !important;
  font-weight: bold;
}

/* -- PAGE 2 -- */
.prizePoolTotal {
  display: flow-root;
  width: 72%;
  padding-left: 65px;
}

.prizePoolTotalLine {
  border: 1px solid var(--stroke);
  width: 400px;
}

.payoutslabels {
  padding-left: 70px;
  padding-top: 10px;;
}

.payoutslabels > h4:nth-child(2) {
  padding-top: 15px;
}

.payoutslabels > h4:nth-child(3) {
  padding-top: 15px;
}

.payoutsBox {
  background-color: var(--subDark);
  /* border: 2px solid var(--stroke); */
  border-radius: 5px;
  padding: 10px;
}

.ant-drawer-body {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.ant-drawer-body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.ant-layout-content {
  padding-bottom: 20px;
  min-height: 100vh;
}

/* FOOTER */
.preFooter {
  height: 3px;
  width: 100%;
  background-color: var(--accent);
}

.ant-layout-footer {
  background-color: var(--footerDark);
}

.coffeeBtn > a > img {
  width: 175px !important;
}